import AnimatedPage from './AnimatedPage';
import ContentSimple from '../components/ContentSimple';
import PhotoRowBackground from '../components/PhotoRowBackground';
import Separator from '../components/Separator';
import Intro from '../components/Intro';
import About from '../components/About';
import Prices from '../components/Prices';

import Button from '../components/Button';
import { ButtonTypes } from '../setup/enum-buttontypes';
import IconPlant from '../components/assets/IconPlant';

function Advies() {
	return (
		<AnimatedPage title={'Advies'}>
			<Intro title="Orthomoleculaire therapie voor honden en katten" subtitle="Advies op maat" logo={false} hideButtonMobile={true} titleSmall={true}>
				<p key="par1">
					Als dierenarts geloof ik in een  <strong>holistische benadering</strong> van de gezondheid van uw huisdier.  Holistisch wil zeggen dat je verder kijkt dan alleen de symptomen en probeert om de onderliggende oorzaak te vinden en het dier als geheel te behandelen. Hierbij kijk je o.a. ook naar de voeding, de beweging en de leefstijl van het dier en diens baasje(s). Behandelmogelijkheden die je hiervoor, naast de diergeneeskunde, kunt toepassen, zijn bijvoorbeeld orthomoleculaire therapie, fytotherapie, fysiotherapie, osteopathie, chiropractie, acupunctuur en homeopathie. Alle zorgen ze ervoor dat het dier in balans komt en blijft door diens <strong>zelfgenezend vermogen</strong> te ondersteunen.
				</p>
				<div className="icon icon--special1">
					<div className="icon__svg">
						<IconPlant />
					</div>
				</div>
			</Intro>
			<ContentSimple
				centered = {false}
				className="contentsimple--white"
				content={
					<>
						<h3>Wat is orthomoleculaire therapie?</h3>
						<p>Orthomoleculaire therapie is gebaseerd op het idee dat je het lichaam voorziet van de juiste
						moleculen in de juiste hoeveelheden om optimaal te functioneren. Dit betekent dat we ons richten
						op het ondersteunen van het lichaam met <strong>essentiële voedingsstoffen</strong>, zoals vitaminen, mineralen,
						vetzuren, aminozuren en antioxidanten. Deze stoffen kunnen via de dagelijkse voeding
						binnenkomen, maar soms is aanvulling via <strong>suppletie</strong> noodzakelijk om tekorten te voorkomen of te
						verhelpen.</p>
						<p>
						In de kern streeft orthomoleculaire therapie naar een <strong>natuurlijke balans</strong> binnen het lichaam,
						waarbij het zelfhelend vermogen wordt gestimuleerd. Het draait om het geven van de juiste
						voedingsstoffen die het lichaam helpen om optimaal te functioneren en ziekten te bestrijden of te
						voorkomen. Orthomoleculaire therapie is een veilige, natuurlijke en effectieve manier om de
						gezondheid van uw huisdier te ondersteunen.
						</p>
					</>
				}
			/>
			<ContentSimple
				centered = {false}
				className="contentsimple--light"
				content={
					<>
						<h3>De holistische kracht van orthomoleculaire therapie</h3>
						<p>Een holistische benadering betekent dat we niet alleen kijken naar de symptomen die uw hond of
kat vertoont, maar ook naar de <strong>onderliggende oorzaken</strong>. Veel gezondheidsproblemen worden
veroorzaakt door disbalans in het lichaam, zoals een tekort aan belangrijke voedingsstoffen of een
ophoping van schadelijke stoffen. Met orthomoleculaire therapie proberen we deze onbalans te
herstellen en de algehele gezondheid van uw dier te verbeteren.
						</p>
					</>
				}
			/>
			<ContentSimple
				className="contentsimple--white"
				content={
					<>
						<h3>Gezondheidsproblemen</h3>
						<p>Orthomoleculaire therapie kan worden ingezet bij een breed scala aan gezondheidsproblemen.
						Denk hierbij aan:
						</p>
						<ul className="blocked">
							<li>Huid- en vachtproblemen</li>
							<li>Allergieën</li>
							<li>Gewrichtsproblemen, zoals artrose</li>
							<li>Maag- en darmproblemen</li>
							<li>Nier- en blaasproblemen</li>
							<li>Verlaagde weerstand</li>
							<li>Gedragsproblemen en stress</li>
							<li>Overgewicht of ondergewicht</li>
							<li>Vermoeidheidsklachten</li>
							<li>Dracht, lactatie en opgroeiende pups of kittens</li>
						</ul>
					</>
				}
			/>
			<ContentSimple
				centered = {false}
				className="contentsimple--white contentsimple--bordertop"
				content={
					<>
						<h3>Voeding, functionele toppings en suppletie</h3>
						<p>De meeste dierenvoeding is een <strong>basisvoeding</strong>; dat wil zeggen dat het voorziet in de essentiële
behoeften die nodig zijn om een hond of kat in leven te houden. Het wil echter niet zeggen dat dit
dieet optimaal is voor de beste gezondheid, energie en levenskwaliteit van het dier. Bij
orthomoleculaire therapie wordt vaak gebruik gemaakt van functionele toppings en suppletie om
de <strong>gezondheid te verbeteren</strong> en tekorten aan te vullen.
						</p>
					</>
				}
			/>
			<PhotoRowBackground imageId="bgpattern" />
			<ContentSimple
				centered = {false}
				className="contentsimple--light"
				content={
					<>
						<h2>Voedingsadvies op maat</h2>
						<p>Geen enkele hond of kat is hetzelfde, dé beste voeding bestaat niet. Daarom maak ik een <strong>persoonlijk plan</strong> dat precies aansluit op de behoeften van uw hond of kat. Dit begint altijd met een
uitgebreide analyse van de gezondheid van uw dier, waarbij we kijken naar huidige voeding,
leefstijl, en eventuele bestaande gezondheidsproblemen en (eerdere) medische problemen.
Vervolgens stel ik een orthomoleculair voedingsadvies op dat is afgestemd op de individuele
behoeften van uw hond of kat.
						</p>
						<p>Het is belangrijk om te beseffen dat bepaalde gezondheidsproblemen vaak over maanden of zelfs
jaren ontstaan, bijvoorbeeld door een ziekte of een langdurig onevenwichtige voeding. Het herstel
hiervan vraagt dan ook tijd en geduld. Helaas bestaat er geen magische oplossing of snelle weg
naar gezondheid. Met het juiste voedingsadvies en wat geduld kunnen we echter stap voor stap
werken aan het verbeteren van de gezondheid van uw hond of kat.
						</p>
					</>
				}
			/>
			<ContentSimple
				className="contentsimple--white"
				button={<Button className="button--main" link={'/contact'} text={'Maak een afspraak'} type={ButtonTypes.ButtonIcon} />}
				content={
					<>
						<h2>Contact opnemen</h2>
						<p>Bent u benieuwd of orthomoleculaire therapie iets voor uw hond of kat kan betekenen? Neem
gerust vrijblijvend contact met mij op voor een consult.</p>
<p>Samen met u zorg ik graag voor een gelukkig en gezond lang leven voor uw trouwe viervoeter!
						</p>
						
					</>
				}
			/>
			<Prices />
			<div className="mobile-hide">
				<Separator type={2} background={'body-background'} />
			</div>
			<ContentSimple
				className="contentsimple--light"
				centered={false}
				button={<Button className="button--main button--small" link={'/voorwaarden'} text={'Voorwaarden'} type={ButtonTypes.ButtonIcon} />}
				content={
					<>
						
						<p>Het uitwerken van een voedingsadvies kost veel (voorbereidings)tijd en het is vaak een puzzel om
tot de kern te komen. Ook het herstel van de problemen van uw hond of kat heeft tijd nodig.
Herstel komt met ups en downs en zal soms weken tot maanden in beslag nemen. Vandaar dat ik 3
maanden begeleiding aanbiedt om u en uw huisdier gedurende het herstelproces te
ondersteunen. Tijdens deze periode monitoren we de voortgang nauwgezet, pas ik het
voedingsadvies aan waar nodig, en beantwoord ik graag uw vragen. Dit zorgt ervoor dat uw hond
of kat de best mogelijke kans heeft om volledig te herstellen en gezond te blijven.</p><p>
Ik werk onafhankelijk en ben niet verbonden een merk of fabrikant. Ik volg geregeld nascholingen
en webinars van verschillende voedings- en suppletiemerken om op de hoogte te blijven van
nieuwe ontwikkelingen. Tevens overleg ik met collegadierenartsen of -orthomoleculair
therapeuten om deze brede kennis en kunde in te kunnen zetten voor uw huisdier.
						</p>
						
					</>
				}
			/>
			<About />
		</AnimatedPage>
	);
}

export default Advies;
